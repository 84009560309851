import Styles from "styles/layout/Footer.module.scss"
import Button from "components/Button"

const Footer = () => {
  const list = [
    {
      icon: "twitter",
      text: "TWITTER",
      link: "/"
    },
    {
      icon: "telegram",
      text: "TELEGRAM",
      link: "/"
    },
    {
      icon: "discord",
      text: "DISCORD",
      link: "/"
    },
    {
      icon: "reddit",
      text: "REDDIT",
      link: "/"
    },
    {
      icon: "youtube",
      text: "YOUTUBE",
      link: "/"
    },
    {
      icon: "coinmarketcap",
      text: "COINMARKETCAP",
      link: "/"
    },
    {
      icon: "dappradar",
      text: "DAPPRADAR",
      link: "/"
    },
    {
      icon: "medium",
      text: "MEDIUM",
      link: "/"
    }
  ]

  let links = []

  list.forEach((item, index) => {
    links.push(
      <div key={index} className="lg:col-3 md:col-4 sm:col-6">
        <Button href={item.link} color="dark" size="lg" reverse={true} icon={item.icon}>
          {item.text}
        </Button>
      </div>
    )
  })

  return (
    <footer className={Styles.footer}>
      <div className="container-fluid flex flex-col block relative z-1">
        <div className={Styles["footer-heading"]}>
          <div className={Styles["footer-heading__top"]}>THE COMMUNITY</div>
          <div className={Styles["footer-heading__title"]}>Making Games to build a universe</div>
        </div>
        <div className={Styles["footer-list"]}>
          <div className="row justify-center">
            <div className="2xl:col-8 xl:col-10">
              <div className={Styles["footer-list__content"] + " " + "row lg:g-10 g-6 justify-center"}>{links}</div>
            </div>
          </div>
        </div>
        <div className={Styles["footer-content"]}>
          <p>
            Mystrios is a part of SKALEverse Ecosystem. SKALE Network is brings speed, decentralization and security to
            Mystrios. You will not pay gas fee for any transaction you will make in Mystrios. Play to Earn with Zero Gas
            Fees.
          </p>
          <p>
            Mystrios.com is a socializing and chat application. It has parts that run on the blockchain and can be used
            with custom plugins.It is not a finance or investment company. It does not promise you profit. It is your
            responsibility to protect your personal information.Any rights acquired on the site or in the game can only
            be used within the game. Some parts of the game are narrated.Game producers or publishers have the right to
            make changes to the content.
          </p>
        </div>
        <div className={Styles["footer-bottom"]}>
          <span>
            <span>Copyright Mystrios.com 2023 Allrights reserved</span>
          </span>
          <span>
            <span>Created by </span>
            <span className="!op-100">DA-S</span>
          </span>
        </div>
      </div>
      <div className={Styles.footer__background}>
        <img src="/images/background.svg" alt="background" />
      </div>
    </footer>
  )
}

export default Footer
