import Footer from "components/layout/Footer"
import Header from "components/layout/Header"
import Cookie from "components/Cookie"

const LandingLayout = ({ children }) => {
  return (
    <>
      <div className="absolute left-0 top-0 xl:mt-10 mt-6 z-8 container-fluid">
        <Header />
      </div>
      <main>{children}</main>
      <Footer />
      <Cookie />
    </>
  )
}
export default LandingLayout
