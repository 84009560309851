import Styles from "styles/pages/Game.module.scss"
import { Layouts } from "components/common/Layouts"
import Play from "Play"

const Game = () => {
  return (
    <Layouts.Game>
      <div className={Styles.game__background}>
        <Play/>
      </div>
    </Layouts.Game>
  )
}

export default Game