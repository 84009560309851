import React, { useEffect } from 'react';
import Phaser, { Game } from "phaser";

import sky from "./assets/sky.png";
import mountains from "./assets/mountains.png";
import plateau from "./assets/plateau.png";
import ground from "./assets/ground.png";
import plant from "./assets/plant.png";

let gameOptions = {
    localStorageName: "mystrios",
    soundOn: true
};

import { createAligned } from "./CreateAligned.js";

const Play = (props) => {

    function usePhaserGame(config) {
        const phaserGameRef = React.useRef(null);
        useEffect(() => {
            // if (player.id) {
            if (phaserGameRef.current) {
                return;
            }
            phaserGameRef.current = new Game(config);
            return () => {
                phaserGameRef.current.destroy(true);
                phaserGameRef.current = null;
            };
            // }
        }, [])
        return phaserGameRef.current;
    }

    class playScene extends Phaser.Scene {

        constructor() {
            super({
                key: "playScene"
            });
        }

        preload() {

            this.load.image("sky", sky);
            this.load.image("mountains", mountains);
            this.load.image("plateau", plateau);
            this.load.image("ground", ground);
            this.load.image("plant", plant);


        }

        create() {

            this.background = this.add.image(
                this.cameras.main.width / 2,
                this.cameras.main.height / 2,
                "sky",
            ).setScrollFactor(0);
            let scaleX = this.cameras.main.width / this.background.width;
            let scaleY = this.cameras.main.height / this.background.height;
            let scale = Math.max(scaleX, scaleY);
            this.background.setScale(scale).setScrollFactor(0);
        }


        update() {
        }
    }

    var config = {
        type: Phaser.AUTO,
        parent: "mystriosgame",
        scale: {
            mode: Phaser.Scale.FIT,
            autoCenter: Phaser.Scale.CENTER_BOTH,
            antialias: true,
            width: window.innerWidth,
            height: window.innerHeight,
            backgroundColor: "#000000",
            transparent: true,
        },
        physics: {
            default: "arcade",
            arcade: {
                gravity: {
                    y: 0,
                },
                debug: false,
            },
        },
        dom: {
            createContainer: true
        },
        scene: [playScene],
    };

    const game = usePhaserGame(config);

    const divStyle = {
        marginLeft: '0px',
        marginRight: '0px',
        marginTop: '0px',
        marginBottom: '0px',
        backgroundColor: '#000000',
    };

    return (
        <div>
            <div id="mystriosgame" style={divStyle}></div>
        </div>
    )
}

export default Play