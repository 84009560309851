"use client"
import { useState } from "react"
import Styles from "styles/layout/Header.module.scss"
import Button from "components/Button"
import Icon from "components/Icon"
import Modal from "components/Modal.jsx"
import Source from "components/modal/Source"
import Characters from "components/modal/Characters"

const Header = ({ game }) => {
  const [isSupportOpen, setIsSupportOpen] = useState(false)
  const [isWalletOpen, setIsWalletOpen] = useState(false)
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const openMenu = () => {
    setIsMenuOpen(true)
  }
  const closeMenu = () => {
    setIsMenuOpen(false)
  }
  const openSupport = () => {
    setIsSupportOpen(true)
    setIsMenuOpen(false)
  }
  const closeSupport = () => {
    setIsSupportOpen(false)
  }
  const openWallet = () => {
    setIsWalletOpen(true)
    setIsMenuOpen(false)
  }
  const closeWallet = () => {
    setIsWalletOpen(false)
  }

  const games = [
    {
      image: "/images/game/games/wheel.svg",
      title: "Lucky Wheel",
      link: "/"
    },
    {
      image: "/images/game/games/ballpool.webp",
      title: "8 ball pool",
      link: "/"
    },
    {
      image: "/images/game/games/bingo.svg",
      title: "bingo",
      link: "/"
    },
    {
      image: "/images/game/games/halloween.svg",
      title: "halloween",
      link: "/"
    }
  ]
  const gameNav = [
    {
      text: "GAME BOX",
      games: true
    },
    {
      text: "MYSTRIUM ORE",
      link: "/"
    },
    {
      text: "MASTERS OF MYSTRIOS",
      link: "/"
    }
  ]
  const nav = [
    {
      text: "Mystrios",
      link: "#promotion"
    },
    {
      text: "faq",
      link: "#join"
    },
    {
      text: "whitepaper",
      link: "#faq"
    },
    {
      text: "community",
      link: "/"
    }
  ]
  let navItems = []
  let navMobileItems = []
  let gameNavItems = []

  nav.forEach((item, index) => {
    navItems.push(
      <a key={index} className={Styles["header-nav__item"]} href={item.link}>
        {item.text}
      </a>
    )
    navMobileItems.push(
      <a onClick={closeMenu} key={index} className={Styles["header-menu__list-item"]} href={item.link}>
        {item.text}
      </a>
    )
  })
  gameNav.forEach((item, index) => {
    gameNavItems.push(
      item.games ? (
        <div key={index} className={Styles["header-nav__item"]}>
          <span>{item.text}</span>
          <ul className={Styles["header-games"]}>
            {games.map((item, index) => (
              <li key={index}>
                <a href={item.link} className={Styles["header-games__item"]}>
                  <span className={Styles["header-games__item-image"]}>
                    <img src={item.image} alt={item.title} />
                  </span>
                  <span className={Styles["header-games__item-title"]}>{item.title}</span>
                </a>
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <a key={index} className={Styles["header-nav__item"]} href={item.link}>
          {item.text}
        </a>
      )
    )
  })

  return (
    <>
      <header className={game ? `${Styles.header} ${Styles["header--game"]}` : `${Styles.header}`}>
        <a href="/" className={Styles.header__logo}>
          <img src="/images/logo.svg" alt="logo" />
        </a>
        <nav className={Styles["header-nav"]}>{game ? gameNavItems : navItems}</nav>
        <div className={Styles["header__actions"]}>
          {!game && <Button color="white">PIONEERS EVENT</Button>}
          <Button onClick={openWallet}>CONNECT WALLET</Button>
        </div>
        <button onClick={openMenu} className={Styles["header__toggle"]} aria-label="toggle button">
          <Icon name="eye" />
        </button>
      </header>
      <div className={isMenuOpen ? `${Styles["header-menu"]} ${Styles["show"]}` : `${Styles["header-menu"]}`}>
        <div className="container-fluid flex flex-col h-full">
          <div className={Styles["header-menu__heading"]}>
            <a href="/" className={Styles.header__logo}>
              <img src="/images/logo.svg" alt="logo" />
            </a>
            <button onClick={closeMenu} className={Styles["header-menu__toggle"]} aria-label="toggle button">
              <Icon name="close" />
            </button>
          </div>
          <div className={Styles["header-menu__list"]}>{navMobileItems}</div>
          <div className={Styles["header-menu__actions"]}>
            <Button size="lg" color="white">
              PIONEERS EVENT
            </Button>
            <Button size="lg" onClick={openWallet}>
              CONNECT WALLET
            </Button>
          </div>
        </div>
      </div>
      <Modal
        isOpen={isSupportOpen}
        onClose={closeSupport}
        heading={
          <div className={Styles["header-modal__heading"]}>
            <img src="/images/header/coins/fox.webp" alt="fox" />
          </div>
        }
        content={<div className={Styles["header-modal__title"]}>Your browser doesnt support Metamask wallet.</div>}
        footer={
          <a className={Styles["header-modal__link"]} href="/">
            Please install on https://metamask.io/
          </a>
        }
      />
      <Modal
        isOpen={isWalletOpen}
        onClose={closeWallet}
        heading={
          <div className={Styles["header-modal__heading"]}>
            <img src="/images/header/coins/skale.webp" alt="skale" />
          </div>
        }
        content={
          <div className={Styles["header-modal__list"]}>
            <Source
              logo="/images/source/skl.svg"
              shorty="skl"
              name="skale fuel"
              value="0.001"
              type="sFuel"
              wallet={true}
            />
            <Source
              logo="/images/source/mys.svg"
              shorty="mys"
              name="MYSTRUIM ore"
              value="0.001"
              type="MYStruim"
              wallet={true}
            />
          </div>
        }
        footer={
          <div className={Styles["header-modal__bottom"]}>
            <div className={Styles["header-modal__bottom-title"]}>NFT LIST</div>
            <div className={Styles["header-modal__bottom-list"]}>
              <Characters />
            </div>
          </div>
        }
      />
    </>
  )
}

export default Header
