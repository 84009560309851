// components/common/Layouts.tsx
import GameLayout from "./GameLayout"
import LandingLayout from "./LandingLayout"

export const Layouts = {
  Landing: LandingLayout,
  Game: GameLayout
}

export default function Layout() {
  return (
    <>
      <Layouts.Landing />
      <Layouts.Game />
    </>
  )
}
